import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContactInfo from './dropdown/ContactInfo';

import SubMenu from './SubMenu';
import Logo from './logo';

import { StyledMenu, StyledMobileHamburgerMenu, StyledCloseIcon, StyledSubMenuNavbar } from './styled';

import { closeMenu } from 'state/actions/drawer';

function Menu({ 
  subMenuData,
  menuData,
  phoneNumber,
  phoneIcon,
  isMenuClosed,
  closeMenu,
  language,
  logoImage,
  location,
  logoImageAltText,
  errorMessage,
  preview
}) {
  const [ isMenuOpen, openMenu ] = useState(false);

  useEffect(() => {
    if (isMenuClosed === false) {
      openMenu(false)
      closeMenu(true)
    }
  }, [isMenuClosed])

  useEffect(() => {
    const body = document.querySelector("body");

    body.style.overflow = isMenuOpen ? "hidden" : "visible";
  }, [isMenuOpen])

  const handleOpenMenu = () => {
    closeMenu(null)
    openMenu(isMenuOpen => {
      return !isMenuOpen
    })
  }

  const menuIcon = isMenuOpen ? menuData.data.close_menu_icon.url : menuData.data.open_menu_hamburger_icon.url;

  const closeMenuButton = (
    <StyledSubMenuNavbar>
      <Logo 
        imgSrc={logoImage} 
        altText={logoImageAltText}
        language={language}
      />
      <StyledCloseIcon> <img onClick={handleOpenMenu} src={menuIcon} /> </StyledCloseIcon>
    </StyledSubMenuNavbar>
  )

  return (
    <React.Fragment>
      
      <StyledMobileHamburgerMenu 
        onClick={handleOpenMenu} 
        src={menuIcon}
      />

      <StyledMenu 
        mode="inline" 
        forceSubMenuRender={true}
        isMenuOpen={isMenuOpen}
      > 

        <div className="navigation-wrapper">

          {closeMenuButton}

          {subMenuData.map((item, index) => {
          return (
            <SubMenu 
              menuItem={item} 
              preview={preview}
              index={index}
              language={language}
              location={location}
              closeMenu={closeMenu}
            />
          )
        })}

          <ContactInfo
            contactInfo={menuData.data}
            phoneNumber={phoneNumber}
            phoneIcon={phoneIcon}
            location={location}
          />

        </div>
      </StyledMenu>
    </React.Fragment>
  )
}

Menu.propTypes = {
  closeMenu: PropTypes.func, 
  errorMessage: PropTypes.string,
  isMenuClosed: PropTypes.bool,
  language: PropTypes.string, 
  location: PropTypes.object,
  logoImage: PropTypes.string,
  logoImageAltText: PropTypes.string,
  menuData: PropTypes.object, 
  phoneIcon: PropTypes.object, 
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  subMenuData: PropTypes.object,
}

const mapStateToProps = state => ({
  isMenuClosed: state.drawerReducer.isMenuClosed
})

const mapDispatchToProps = dispatch => ({
  closeMenu: isMenuClosed => dispatch(closeMenu(isMenuClosed))
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
