import React from 'react'
import { Elements } from 'prismic-reactjs'
import loadable from '@loadable/component'
import LazyLoad from 'react-lazyload';
//HTML serializer functions imports

import Image from './functions/Image'
import Heading1 from './functions/Heading1'
import Heading2 from './functions/Heading2'
import Heading3 from './functions/Heading3'
import Heading4 from './functions/Heading4'
import Heading5 from './functions/Heading5'
import Heading6 from './functions/Heading6'
import CheckCenteredText from './functions/CheckCenteredText'
import CTAButton from './functions/CTAButton'
import Paragraph from './functions/Paragraph'
import RichTextLink from './functions/RichTextLink/index.js'
import List from './functions/List'
import Table from './functions/Table'
import Preformatted from './functions/Preformatted'

import 'styles/index.scss';

const LoadableEmbed = loadable(() => import(/* webpackChunkName: "gatsby-Embed" */ /* webpackPrefetch: true */ './functions/Embed/index'));

const LoadableSingleMarkerMap = loadable(() => import(/* webpackChunkName: "gatsby-SingleMarkerMap" */ 'components/maps/SingleMarkerMap'));

export const htmlSerializer = (type, element, content, children, key) => {

  switch (type) {
    //serialize <p> tag
    case Elements.paragraph:
      //serialize table html

      if (element.text === "View Larger Map") {
        return <React.Fragment />
      }

      const getLabel = element.spans.find(el => el.data && el.data.label === "table")

      if (getLabel) {
        const tableLabel = getLabel.data.label;

        if (tableLabel === "table") {
          return (
            <Table 
              children={children} 
              element={element}
              key={key}
            />
          )
        }
        
      } else if (element.label === 'table') {
        return (
          <Table 
            children={children} 
            element={element}
            key={key}
          />
        )
      }
      //render textarea
      if (element.label === 'text-area') {

        return (
          <div 
            className={element.label}
            style={{ fontFamily: 'inherit', fontSize: 'inherit', width: '100%' }} 
            dangerouslySetInnerHTML={{ __html: element.text }} 
          />
        )
      }

      //serialize map html
      if (element.label === 'map') {
        const coords = element.text.split(',')

        return(
          <LazyLoad
            height={200}
            once
            offset={-100}
          >
            <LoadableSingleMarkerMap 
              destinationLocationData={{latitude: Number(coords[0]), longitude: Number(coords[1])}} 
            />
          </LazyLoad>
        )
      } 

      const isParagraphCentered = CheckCenteredText(element);

      return (
        <Paragraph 
          children={children} 
          id={key}
          element={element}
          key={key}
          style={isParagraphCentered && {textAlign: 'center'}}
        />
      )

    // Preformatted
    case Elements.preformatted: 

      return (
        <Preformatted 
          children={children} 
          element={element}
          key={key} 
        />
      )

    //serialize embeded elements
    case Elements.embed:
      return (
        <LazyLoad>
        
          <LoadableEmbed 
            element={element} 
            slice={false}
          />
        </LazyLoad>)

    //serialize <img> tag
    case Elements.image:
      return (
        <Image 
          element={element} 
          key={key} 
        />
      )

    //serialize <h1> tag
    case Elements.heading1:
      return (
        <Heading1
          children={children}
          richText={true}
          key={key}
        />)

    //serialize <h2> tag
    case Elements.heading2:
      const isH2Centered = CheckCenteredText(element);

      return (
        <Heading2
          children={children} 
          richtext={true}
          key={key}
          style={isH2Centered && {textAlign: 'center'}}
        />
      )

    //serialize <h3> tag
    case Elements.heading3:
      return (
        <Heading3
          children={children} 
          richText={true}
          key={key}
          content={content}
          element={element}
        />
      )

    //serialize <h4> tag
    case Elements.heading4:
      return (
        <Heading4
          children={children} 
          richText={true}
          key={key}
        />
      )

    //serialize <h5> tag
    case Elements.heading5:
      return (
        <Heading5
          children={children} 
          richText={true}
          key={key}
        />
      )

    //serialize <h6> tag
    case Elements.heading6:
      return (
        <Heading6
          children={children} 
          richText={true}
          key={key}
        />
      )

    //Unordered List
    case Elements.list:
      return (
        <List
          children={children} 
          element={element}
          key={key}
        />
      )
    
    case Elements.hyperlink:
      return ( 
        <RichTextLink 
          children={children}
          element={element} 
          content={content}
        />
      )
      
    //serialize elements with labels
    case Elements.label:
      if (element.data !== undefined) {
        //hide google maps button text scraped from website
        if (element.data.label === 'google-maps-button') {
          return <React.Fragment />
        }

        if (element.data.label === 'CTAButton') {
          return (
            <CTAButton
              element={element} 
              slice={false}
              url={element.data.url}
              content={content}
              children={children} 
              key={key}
            />
          )
        }

        if(element.data.label === 'fxair-cta-link'){
          return (
            <CTAButton
              element={element} 
              slice={false}
              url={children[0]?.props?.element?.data?.url}
              content={content}
              children={children} 
              key={key}
            />
          )
        }

        if (element.label === 'table') {
          return (
            <Table 
              children={children} 
              element={element}
              key={key}
            />
          )
        }

        if (element.data.label === 'trustarc-cookie-setting') {
          return (
            <a  
              className="trustarc-cookie-link"
              href = "#"     
              onClick = {(e) => {
                e.preventDefault();
                typeof window !== 'undefined' && window.truste.eu && window.truste.eu.clickListener();
              }}
            > 
              {children} 
            </a>
          )
        }

      }

    default:
      return null
  }
}
