import React from 'react';
import PropTypes from 'prop-types';

import { StyledContactWrapper, StyledPhoneNumber, Paragraph } from '../styled';

const gtmCode = process.env.GTM_CODE;

const ContactInfo = ({ contactInfo, phoneNumber, location }) => {

  const hasRulertel = gtmCode === "GTM-NJ3MRXX" || gtmCode === "GTM-WTMCFMK";
  const isJetCardPage = location.pathname.includes("jet-card");
  const rulerPhoneNumber = phoneNumber;
  // On pages such as Jet Card where phone number comes from Prismic remove the (0) from the phone number 102990
  const hrefPhoneNumber = rulerPhoneNumber && rulerPhoneNumber.replace(/\+44\s\(0\)/g, '+44');

  return (
    <StyledContactWrapper>
      <Paragraph>{contactInfo.contact_text}</Paragraph>
      <StyledPhoneNumber 
        id="nav__contact-phone" 
        className="rulertel"
        href={`tel:${hrefPhoneNumber}`}
      >
        {rulerPhoneNumber}
      </StyledPhoneNumber>
    </StyledContactWrapper>
  )
}

export default ContactInfo

ContactInfo.propTypes = {
  contactInfo: PropTypes.object,
  location: PropTypes.object,
  phoneNumber: PropTypes.string
}
