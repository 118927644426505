import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Logo from './logo';
import Menu from './Menu';

import createProperties from 'utils/functions/createProperties';

import { StyledMobileHeader } from './styled';

class Mobile extends Component {
  constructor(props) {
    super(props)
    this.windowGlobal = typeof window !== 'undefined' && window
  }

  render() {
    const { 
      menuData, 
      preview, 
      location,
      phoneNumber, 
      socialMediaIcons,
      errorMessages,
      phoneIcon,
      language
    } = this.props;

    const { urls } = createProperties(language);
    
    const logoImage = menuData.data.header_logo.url;
    const logoImageAltText = menuData.data.header_logo.alt || "";

    return (
      <StyledMobileHeader id="mobile-header" className="header">
        <Logo 
          imgSrc={logoImage} 
          altText={logoImageAltText}
          language={language}
        />
        <Menu 
          subMenuData={menuData.data.body}
          socialMediaIcons={socialMediaIcons}
          menuData={menuData}
          phoneNumber={phoneNumber}
          urls={urls}
          phoneIcon={phoneIcon}
          language={language}
          logoImage={logoImage} 
          location={location}
          preview={preview}
          logoImageAltText={logoImageAltText}
          errorMessage={errorMessages}
        />
      </StyledMobileHeader>
    )
  }
}

export default Mobile

Mobile.propTypes = {
  errorMessages: PropTypes.object,
  language: PropTypes.string,
  location: PropTypes.object, 
  menuData: PropTypes.object,
  phoneIcon: PropTypes.object,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  socialMediaIcons: PropTypes.array
}