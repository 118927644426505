import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import SubMenuList from './SubMenuList';

import { StyledMenuItemsWrapper, StyledOverlay } from '../styled';

import { FullWidthContainer } from 'components/grid/styled';

const Dropdown = ({ menuData, setDropdown, displayMenuDropdown, searchErrorMessage, language, location, preview}) => {

  useEffect(() => {
		setDropdown(false);
		window.addEventListener('mousedown', handleClickOutside);
	}, [])

	const handleClickOutside = (e) => {
		if (e.target && e.target.id && e.target.id === "navigation-overlay") {
			setDropdown(false)
		}
	}

  return (
    <React.Fragment>
      
      {displayMenuDropdown ? <StyledOverlay id="navigation-overlay" /> : null}	

      <StyledMenuItemsWrapper isActive={displayMenuDropdown}>	
        <FullWidthContainer style={{justifyContent:'space-between'}}>
            
          { menuData.data.body.map((item) => {
              return (
                <SubMenuList
                  sublist={item} 
                  language={language}
                  location={location}
                  preview={preview}
                  setDropdown={setDropdown}
                />
              )
            })
          }

        </FullWidthContainer>
        <br />
      </StyledMenuItemsWrapper> 
        
    </React.Fragment>
  )
}

export default Dropdown

Dropdown.propTypes = {
	displayMenuDropdown: PropTypes.bool,
	language: PropTypes.string,
  location: PropTypes.object,
	menuData: PropTypes.object,
  preview: PropTypes.bool,
	searchErrorMessage:PropTypes.string,
  setDropdown: PropTypes.func
}
