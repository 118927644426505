import React from 'react';
import PropTypes from 'prop-types';

import Article from 'components/footer/Article';
import ContactAndSocial from 'components/footer/ContactAndSocial';
import LanguageSelect from 'components/footer/LanguageSelect';
import TermsLinks from 'components/footer/TermsLinks';

import { GridRow, FullWidthContainer } from 'components/grid/styled';
import { StyledFooter, StyledFooterTiles, StyledBackToTopButton } from './styled';

const windowGlobal = typeof window !== 'undefined' ? window : {};

// 96824 - this file incl preview changes

const Footer = ({ 
  localePhoneNumber, 
  isHomePage,
  preview,
  previewFooterData,
  language,
  footerProps,
  alternateLanguages,
  location,
}) => {
  const footerData = previewFooterData ? previewFooterData : footerProps.node;
  const { back_to_top_button } = footerData.data;
  const defaultFlag = footerData.data.language.find(el => el.country_code === "en-gb");
    
  return (
    <GridRow 
      backgroundColor='#D4D6DE' 
      marginTop="auto"
     
      bottom="0%"
    >
      <FullWidthContainer>
        <StyledFooter>
          <StyledFooterTiles>
            
            {footerData.data.footer_article_tiles[0] &&  
              <Article 
                width="28%"
                footerData={footerData.data.footer_article_tiles[0]} 
                language={language}
                preview={preview}
              />
            }

            {footerData.data.footer_article_tiles[1] &&  
              <Article 
                width="32%"
                footerData={footerData.data.footer_article_tiles[1]} 
                language={language}
                preview={preview}
              />
            }

            <ContactAndSocial 
              footerData={footerData.data}
              phoneNumber={preview ? '+44 (0)20 7100 6960' : localePhoneNumber} 
              preview={preview}
              previewFooterData={previewFooterData}
              location={location}
            />
            <LanguageSelect 
              footerData={footerData.data}
              preview={preview}
              language={language}
              location={location}
              alternateLanguages={alternateLanguages}
              defaultFlag={defaultFlag}
            />

          </StyledFooterTiles>

          <TermsLinks 
            footerData={footerData.data} 
            language={language}
          /> 

          <StyledBackToTopButton onClick={ () => windowGlobal.scrollTo({ top: 0, behavior: "smooth" }) }>
            {back_to_top_button ? back_to_top_button : ""}
          </StyledBackToTopButton>
        </StyledFooter>
      </FullWidthContainer>
    </GridRow>
  );
};

export default Footer;

Footer.propTypes = {
  alternateLanguages: PropTypes.array,
  footerProps: PropTypes.object,
  isHomePage: PropTypes.bool,
  language: PropTypes.string,
  localePhoneNumber: PropTypes.string,
  location: PropTypes.object,
  preview: PropTypes.bool,
  previewFooterData: PropTypes.object
}
