import React from 'react';
import PropTypes from 'prop-types';

import RenderRichText from 'utils/richText/renderRichText';

import { StyledFxAirBanner, StyledFxAirBannerImg, StyledFxAirBannerText } from './styled';

const FxAirBanner = ({
  preview,
  language,
  headerData
}) => {

   const data = headerData.data;
   const isFR = language === 'fr-fr' || process.env.LANG_CODE === 'fr-fr';
   const fxAirIconData = headerData.data.privatefly_to_fxair_logo;
   const fxAirTextData = preview ? headerData.data.privatefly_to_fxair_text : headerData.data.privatefly_to_fxair_text.raw;

  return (
    <StyledFxAirBanner> 
      <div className='wrapper'>
        <StyledFxAirBannerImg href = {data.privatefly_to_fxair_logo_link.url}>
          <img src={fxAirIconData.url} alt={fxAirIconData.alt} />
        </StyledFxAirBannerImg>
        
        <StyledFxAirBannerText isFR={isFR ? true : false}>
          <RenderRichText richTextArray={fxAirTextData} />
        </StyledFxAirBannerText>
      </div>
    </StyledFxAirBanner>
  )
}

export default FxAirBanner;

FxAirBanner.propTypes = {
  headerData: PropTypes.object,
  language: PropTypes.string,
  preview: PropTypes.bool,
}
