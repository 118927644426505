import React from 'react';
import PropTypes from 'prop-types';

import HeaderWrapper from 'components/HOC/HeaderWrapper';
import Desktop from './DesktopNavbar';
import Mobile from './MobileNavbar';

const Header = ({
  localePhoneNumber, 
  location, 
  errorMessages, 
  previewHeaderData, 
  preview,
  language,
  alternateLanguages,
  headerData
}) => {
  return (
    <React.Fragment>
      <Desktop
        location={location}
        menuData={headerData}
        preview={preview}
        phoneNumber={localePhoneNumber}
        errorMessages={errorMessages}
        previewHeaderData={previewHeaderData}
        language={language}
        alternateLanguages={alternateLanguages}
      />
      <Mobile
        location={location}
        menuData={headerData}
        preview={preview}
        phoneNumber={localePhoneNumber}
        errorMessages={errorMessages}
        previewHeaderData={previewHeaderData}
        language={language}
        alternateLanguages={alternateLanguages}
      />
    </React.Fragment>
  )
  
}

export default HeaderWrapper(Header);

Header.propTypes = {
  alternateLanguages: PropTypes.array,
  errorMessages: PropTypes.object,
  headerData: PropTypes.object,
  language: PropTypes.string,
  localePhoneNumber: PropTypes.string,
  location: PropTypes.object,
  preview: PropTypes.bool,
  previewHeaderData: PropTypes.object,
}
