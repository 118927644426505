export const modifyFxAirLinks = () => {
  if (typeof window === "undefined") return;

  const FXAIR_DOMAIN = 'fxair.com';
  const links = document.querySelectorAll('a');

  links.forEach(link => {
    try {
      const url = new URL(link.href);
      
      if (url.hostname === FXAIR_DOMAIN || url.hostname === `www.${FXAIR_DOMAIN}`) {

        // search if url.pathname does not contain /en-gb then add it
        if (!url.pathname.includes('/en-gb')) {
          url.pathname = '/en-gb' + url.pathname;
        }
        
        console.log("fxair link detected on page");

        const searchParams = new URLSearchParams(url.search);
        
        searchParams.set('utm_source', window.location.href);
        url.search = searchParams.toString();
        link.href = url.toString();
      }
    } catch (error) {
      console.warn('Invalid URL found:', link.href);
    }
  });
};