import React from "react";
import PropTypes from 'prop-types';

import { StyledHeading, StyledTileWrapper, SocialMediaWrapper, StyledPhoneLink} from "../styled";

const gtmCode = process.env.GTM_CODE;

const ContactAndSocial = ({ footerData, phoneNumber, location}) => {
  
  const socialMediaIcons = footerData.social_media_icons_footer && footerData.social_media_icons_footer.map(el => {
    const title = el.social_media_title;
    const link = el.social_media_icon_link.url;
    const linkTarget = el.social_media_icon_link.target;

    return (
      <a 
        href={link && link}
        target={linkTarget && linkTarget}
        title={title && title} 
        style={{marginRight: '28px'}}
      >
        <img style={{width: '30px'}} src={el.social_media_icon.url} /> 
      </a>
    )
  }) 

  const hasRulertel = gtmCode === "GTM-NJ3MRXX" || gtmCode === "GTM-WTMCFMK";
  const isJetCardPage = location.pathname.includes("jet-card");
  const rulerPhoneNumber = phoneNumber;
  // On pages such as Jet Card where phone number comes from Prismic remove the (0) from the phone number 102990
  const hrefPhoneNumber = rulerPhoneNumber && rulerPhoneNumber.replace(/\+44\s\(0\)/g, '+44');

  return (
    <StyledTileWrapper width='24%' padding="22px 0px">
      <StyledHeading marginBottom="11px"> {footerData.footer_contact_section_heading} </StyledHeading>
      <StyledPhoneLink className="rulertel" href={`tel:${hrefPhoneNumber}`}> {rulerPhoneNumber} </StyledPhoneLink>      
    </StyledTileWrapper>
  );
};

ContactAndSocial.propTypes = {
  footerData: PropTypes.object, 
  location: PropTypes.object,
  phoneNumber: PropTypes.string,
}

export default ContactAndSocial;
