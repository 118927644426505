import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/buttons/button'
import InternalLink from 'components/links/internalLink';

const CTAButton = ({ element, slice, url, content, language, isFullWidthButton }) => {
  let parsedUrl;

  const [langCode, setLangCode ] = useState("");

  const buttonWidth = isFullWidthButton ? '100%' : 'initial';

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const langCodeFromLocalStorage = localStorage.getItem("langCode");

      setLangCode(langCodeFromLocalStorage)
    } else {
      setLangCode("")
    }
  })

  if (url) {
    parsedUrl = new URL(url);
  } else if (element) {
    if (element.url) {
      parsedUrl = new URL(element.url);
    }
  }

  if (parsedUrl && parsedUrl.host.indexOf("www.privatefly") > -1) {
    return (
      <InternalLink 
        key={parsedUrl.pathname}
        path={parsedUrl.pathname}
        language={language ? language : langCode}
        linkComponent={
          <Button
            className={"cta-button"}
            text={content}
            fontWeight={[500]}
            fontColor="white"
            paddingLeft="1.75em"
            paddingRight="1.75em"
            width={buttonWidth}
          />
      }
      />
    )
  } else if (parsedUrl && parsedUrl.host.indexOf("privatefly") -1) {

    return (
      <a href={url}>
        <Button
          className={"cta-button"}
          text={content}
          fontWeight={[500]}
          fontColor="white"
          paddingLeft="1.75em"
          paddingRight="1.75em"
          width={buttonWidth}
        />
      </a>
    )
  } else {
    return (
      <Button
        className={"cta-button"}
        text={content}
        fontWeight={[500]}
        fontColor="white"
        paddingLeft="1.75em"
        paddingRight="1.75em"
        width={buttonWidth}
      />
    )
  }

  // return (
  //   <React.Fragment>
  //     {
  //       parsedUrl && parsedUrl.host.indexOf("www.privatefly") > -1 ?
  //         <InternalLink 
  //           key={parsedUrl.pathname}
  //           path={parsedUrl.pathname}
  //           language={language ? language : langCode}
  //           linkComponent={
  //             <Button
  //               className={"cta-button"}
  //               text={content}
  //               fontWeight={[500]}
  //               fontColor="white"
  //               paddingLeft="1.75em"
  //               paddingRight="1.75em"
  //             />
  //           }
  //         />

  //     :
        
  //         <Button
  //           className={"cta-button"}
  //           text={content}
  //           fontWeight={[500]}
  //           fontColor="white"
  //           paddingLeft="1.75em"
  //           paddingRight="1.75em"
  //         />
     
  //   }
  //   </React.Fragment>
  // )
}

CTAButton.propTypes = {
  content: PropTypes.string,
  element: PropTypes.object,
  isFullWidthButton: PropTypes.bool,
  language: PropTypes.string,
  slice: PropTypes.object,
  url: PropTypes.string
}

export default CTAButton;