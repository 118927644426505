import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import loadable from '@loadable/component';

import { StyledAnchor, GatsbyLink } from 'utils/richText/styled';
import InternalLink from 'components/links/internalLink';

import { linkResolver } from 'utils/linkResolver';

import { previewLinkResolver } from 'utils/previewLinkResolver';
import createProperties from 'utils/functions/createProperties';

const LoadablePrismic = loadable(() => import(/* webpackChunkName: "gatsby-PrismicJavascript" */ 'prismic-javascript'));

const host = process.env.LOCALE_ORIGIN;

const RichTextLink = ({ children, element, content, key, preview, lang }) => {
  const [document, setDocument ] = useState(null)
  const isBlogCheck = process.env.BUILD_BLOG;
  const privateflyDomain = process.env.LOCALE_ORIGIN;
  const { prismicEndpoint } = createProperties();

  if (preview) {
    const client = LoadablePrismic.client(prismicEndpoint)

    if (element.data.link_type === "Document") {
      client
        .getByUID(element.data.type, element.data.uid, { lang: '*' })
        .then(res => {
          setDocument(res)

          return res
        })

      const previewDocumentHref = previewLinkResolver(document)

      return (
        <GatsbyLink 
          to={previewDocumentHref}
          key={previewDocumentHref}
        >
          {children}
        </GatsbyLink>
      )
    }
  }

  const data = element.data;
  const child = children[0] && children[0].props;
  const targetAttr = data && data.target;
  let checkFlag;

  if (typeof window !== 'undefined') {
    checkFlag = window.location.href.match(/N188|N114|N286|N153/g);
  }

  const relAttr = child && child.className
    ? child.className
    : `noopener`;

  //check if <a> tag is a CTA button by checking if it has a label
  let isCTAButton = children.some(el => {
    if (el) {
      if (el.props) {
        if (el.props.element) {
          if (el.props.element.data) {
            // console.warn('isCTAButton SUNIL', el.props.element.data);
            if (el.props.element.data.label === "CTAButton") return true;
          }
        }
      }
    }
  });

  let href = (data && data.link_type == 'Web' && data.url) || linkResolver(data)

  let url

  if (!href.startsWith("tel") && !href.startsWith("https://#")) {
    url = new URL(href, host);
    if (isBlogCheck) {
      url = new URL(href);
    }
  }
  
  if (href.startsWith("https://#") ) {
    href = href.slice(href.indexOf("#"))
    url = new URL(href, host);

  }

  const isInternalLink = /^\/(?!\/)/.test(href);
  const hostnamePattern = /privatefly/g;
  const blogPattern = /blog.privatefly/g
  const isPrivateflyLink = url && url.hostname.match(hostnamePattern)
  const isBlogLink = url && url.hostname.match(blogPattern)
  const isAnchorLink = url && url.hash;

  //check if a link is a CTA button
  if (isCTAButton) {
      //check if CTA button is an internal link
    if ((isInternalLink) && (!isBlogLink)) {
      return (
        <InternalLink 
          rel={relAttr}
          key={href}
          path={href}
          language={lang}
          linkComponent={children}
          target={targetAttr}
        />
      )
    } else {
      //check if CTA button links back to privatefly.com domain
      if (isPrivateflyLink) {
        if (isBlogCheck) {
          let newUrl;

          if (url.pathname.startsWith("//")) {
            newUrl = new URL("https:" + url.pathname)
          }

          if (newUrl) {
            return (
              <StyledAnchor 
                rel={relAttr}
                href={`${privateflyDomain}${newUrl.pathname}`}
                target={targetAttr}
                key={href}
              >
                {children}
              </StyledAnchor>
            )
          }

        } else {
          return (
            <InternalLink 
              rel={relAttr}
              key={href}
              path={url.pathname}
              language={lang}
              linkComponent={children}
              target={targetAttr}
            />
          )
        }

      }
        if (isBlogCheck) {

          return (
            <StyledAnchor 
              rel={relAttr}
              href={`${privateflyDomain}${url.pathname}`}
              target={targetAttr}
              key={href}
            >
              {children}
            </StyledAnchor>
          )
        } else {
          return (
            <StyledAnchor 
              rel={relAttr}
              href={url.pathname}
              target={targetAttr}
              key={href}
            >
              {children}
            </StyledAnchor>
          )
        }
    }
  }

  //check if a link is an anchor link #
    if (isAnchorLink) {
      return (
        <StyledAnchor 
          rel={relAttr}
          href={isAnchorLink}
          target={targetAttr}
          key={href}
        >
          {children}
        </StyledAnchor>
      )
    }

    //check if <a> tag is a telephoner number
    if (href.startsWith('tel')) {
      let decodedTelephoneNumber = decodeURIComponent(href)

      return (
        <StyledAnchor 
          rel={relAttr}
          href={decodedTelephoneNumber}
          target={targetAttr}
          key={href}
          className="rulertel"
        >
          {children}
        </StyledAnchor>
      )
    }

    //check if link is an email link
    if (href.startsWith('mailto')) {
      return (
        <StyledAnchor 
          rel={relAttr}
          href={href}
          target={targetAttr}
          key={href}
        >
          {children}
        </StyledAnchor>
      )
    }

    //check if is a blog
    if (isBlogLink) {
      //check if a blog page
      if (isBlogCheck) {
        return (
          <GatsbyLink 
            rel={relAttr}
            to={href}
            target={targetAttr}
            key={href}
          >
            {children}
          </GatsbyLink>
        )
      } else {
        return (
          <StyledAnchor 
            rel={relAttr}
            href={href}
            target={targetAttr}
            key={href}
          >
            {children}
          </StyledAnchor>
        )
      }
    }

    //check if url starts with https://www.privatefly.com or www.privatefly.com and convert to relative link otherwise return a full link
    if (isPrivateflyLink) {
      if (isBlogCheck) {

        return (
          <StyledAnchor 
            rel={relAttr}
            href={`${privateflyDomain}${url.pathname}`}
            target={targetAttr}
            key={href}
          >
            {children}
          </StyledAnchor>
        )
      } else {
        return (
          <InternalLink 
            target={targetAttr}
            key={href}
            rel={relAttr}
            path={url.pathname}
            language={lang}
            linkComponent={children}
            className="article-link"
          />
          
        )
      }

    } else if (isInternalLink) {
      //check if link is an internal link
      
      return (
        <InternalLink 
          target={targetAttr}
          key={href}
          rel={relAttr}
          path={href}
          language={lang}
          linkComponent={children}
          className="article-link"
        />
      ) 
    } else {
      return (
        <StyledAnchor 
          rel={relAttr}
          href={href}
          target={targetAttr}
          key={href}
        >
          {children}
        </StyledAnchor>
      )
}
}

const mapStateToProps = state => ({
  lang: state.languageReducer.lang
});

export default connect(mapStateToProps, null)(RichTextLink)

RichTextLink.propTypes = {
  children: PropTypes.array,
  content: PropTypes.string,
  element: PropTypes.object
}
